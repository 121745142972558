import isMobile from "is-mobile";
import revive, { iddle } from "@/js/extra/revive"

// Islands
const islands = {
  ...import.meta.glob('@/js/sections/*.js'),
  ...import.meta.glob('@/js/templates/*.js'),
  ...import.meta.glob('@/js/modules/*.js')
}

revive(islands)

async function loadLibraries() {
  await iddle()

  import('@/js/extra/load-libraries.js')
}

loadLibraries()

// eslint-disable-next-line no-prototype-builtins
if (!HTMLElement.prototype.hasOwnProperty("inert")) {
  import( /* @vite-ignore */ flu.chunks.polyfillInert);
}

if (isMobile({ tablet: true, featureDetect: true })) {
  add(document.body, "is-mobile");
}